/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

'use client'

import Cookies from 'js-cookie'
import { useRouter } from 'next/navigation'
import { useEffect } from 'react'

import { useProfile } from '~/backend/gmx-api/profile/Profile.query'
import Spinner from '~/components/Spinner'
import { REDIRECT_URL_KEY } from '~/utils/consts'

const defaultPages = {
  ADMIN: '/games/all',
  CONTENT_MANAGER: '/games/all',
  DYNAMIC_CONFIG_MANAGER: '/dynamic-configs/all',
  GM_OPS: '/mods/gms-runners',
  OFFER_MANAGER: '/offers/all',
  VIEWER: '/games/all',
}

export default function Page() {
  const router = useRouter()
  const profile = useProfile({})

  useEffect(() => {
    if (!profile.data?.active) {
      return
    }

    const redirectUrl = Cookies.get(REDIRECT_URL_KEY)
    if (redirectUrl && redirectUrl !== '/') {
      Cookies.remove(REDIRECT_URL_KEY)
      router.push(redirectUrl)
      return
    }

    const firstRole = profile.data.roles[0]
    if (firstRole) {
      router.push(defaultPages[firstRole])
    }
  }, [profile.data, profile.data?.roles, router])

  return <Spinner />
}
