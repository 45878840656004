/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`
const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const Spinner = styled.div<{ $scale?: number }>`
  display: inline-block;
  width: calc(${({ $scale }) => $scale ?? 1} * 32px);
  height: calc(${({ $scale }) => $scale ?? 1} * 32px);

  animation:
    ${fadeIn} 0.5s linear 0.1s forwards,
    ${rotate} 0.75s linear infinite;

  border: calc(${({ $scale }) => $scale ?? 1} * 4px) solid ${({ theme }) => theme.colors.neutral100};
  border-radius: 50%;
  border-right-color: transparent;

  opacity: 0;
`

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

export const CenteredSpinner = (props: { className?: string; scale?: number }) => (
  <Center className={props.className}>
    <Spinner $scale={props.scale} />
  </Center>
)

export default Spinner
